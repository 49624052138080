const _temp0 = require("../img/news/22012025/news01.jpg");
const _temp1 = require("../img/news/22012025/news02.jpg");
const _temp2 = require("../img/news/22012025/news03.jpg");
const _temp3 = require("../img/news/22012025/news04.jpg");
const _temp4 = require("../img/news/22012025/news05.jpg");
const _temp5 = require("../img/news/22012025/news06.jpg");
const _temp6 = require("../img/news/22012025/news07.jpg");
const _temp7 = require("../img/news/22012025/news08.jpg");
const _temp8 = require("../img/news/22012025/news09.jpg");
module.exports = {
  "news01": _temp0,
  "news02": _temp1,
  "news03": _temp2,
  "news04": _temp3,
  "news05": _temp4,
  "news06": _temp5,
  "news07": _temp6,
  "news08": _temp7,
  "news09": _temp8
}