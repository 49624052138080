import {tns} from "tiny-slider/src/tiny-slider";
import {loadAdvertisers, newsRefs} from "./config";
import MicroModal from "micromodal";

export var layout = {
  width: window.innerWidth,
  height: window.innerHeight - 120,
  mobile: window.innerWidth <= 768
};

export var render = {
  canvasScale: window.devicePixelRatio,
  liveCanvas: null,
  pauseRendering: false
};

export var charts = {
  activeCharts: {}
};

export var map = {
  instance: null,
  refZoom: null,
  bgPoints: [],
  currentFocus: null,
  activeMap: 'wm',
  popup: null,
  coastLine: null,
  windLayer: null,
  windBox: {},
  windWidth: null,
  windHeight: null,
  wavesLayer: null,
  wavesBox: {},
  wavesWidth: null,
  wavesHeight: null,
  rasterWidth: null,
  rasterHeight: null
};

export var layers = {
  forecastsLayer: null,
  overlay: null,
  tiles: null,
  bgLayers: [],
  dataLayers: ['wind', 'waves']
};

export var data = {
  coastData: null,
  windData: [],
  timelineData: {},
  sensorsPolling: null
};

export var timeline = {
  timelineStep: 600,
  timelineSteps: null,
  timelineReference: null,
  timelineActiveStep: null,
  timelineInterval: null,
  timelineSlider: null,
  timelinePosition: null,
  stepWidth: 10,
  cursorOffset: layout.width / 2.0 - (window.innerWidth <= 768 ? 0 : 232),
  details: false,
  detailsStepWidth: 5,
  detailsCursorOffset: layout.width / 2.0 - (window.innerWidth <= 768 ? 0 : 216),
  scrolling: false,
  moving: false,
  skipUpdate: true,
  keyBoardSetUp: false,
  setUp: false,
  hourLabel: document.querySelector("#timeline_hour"),
  dateLabel: document.querySelector("#timeline_date"),
  hoursCount: Math.floor(((window.innerWidth / 2) - 256) / (6 * 10)),
  hoursCountDetails: Math.floor(((window.innerWidth / 2) - 240) / (6 * 5)),
}

export function updateLayout() {
  const isMobile = window.innerWidth <= 768, bottomMargin = isMobile ? 120 : 150;
  layout.width = window.innerWidth;
  layout.height = window.innerHeight - bottomMargin;

  render.canvasScale = window.devicePixelRatio;
}

export function sliderDisplay(id, opts) {
  const defaultOpts = {
    container: document.getElementById(id),
    items: 1,
    edgePadding: 0,
    gutter: 20,
    autoplayTimeout: 10000,
    autoplay: true,
    swipeAngle: false,
    speed: 1000,
    loop: true,
    nav: false,
    autoplayButtonOutput: false,
    autoplayButton: false,
    controls: false,

    responsive: {
      640: {
        items: 2
      },
      1024: {
        items: 3
      }
    }
  };
  return tns({...defaultOpts, ...(opts || {})});
}

export function initSlides(completedSlide){
  loadAdvertisers(completedSlide);
}

export function createSlides(data, id) {
  let wrapper = document.getElementById(id), i;
  if (data.length > 0) {
    for (i = 0; i < data.length; i++) {
      let container = document.createElement("div"), imgWrapper;
      wrapper.append(container);
      if (data[i]["url"]) {
        imgWrapper = document.createElement("a");
        imgWrapper.target = "_blank";
        imgWrapper.href = data[i]["url"];
      } else {
        imgWrapper = document.createElement("div");
      }
      container.append(imgWrapper);
      let picture = document.createElement("img");
      picture.classList.add("img-responsive");
      picture.src = data[i]["path"];
      imgWrapper.append(picture);
    }
  }
}

export function displayNews(timeout) {
  setTimeout(function() {
    MicroModal.show('news_modal', {disableScroll: true});
    if (document.querySelectorAll("#news_modal #news_slides img").length === 0) {
      createSlides(newsRefs,"news_slides")
      sliderDisplay("news_slides", {
        controls: true, controlsText: ['<i class="fa fa-chevron-circle-left"></i>', '<i class="fa fa-chevron-circle-right"></i>'],
        nav: true, navPosition: 'bottom',
        responsive: {640: {items: 1}, 1024: {items: 1}}
      });
      localStorage.setItem('news-22012025', (new Date()).getTime());
    }
  }, timeout);
}

export function logPageView(lastRoute, path, title) {
  let firstPage = lastRoute === null;
  if (!firstPage) {
    _paq.push(['setCustomUrl', path]);
    _paq.push(['setDocumentTitle', title]);
    _paq.push(['trackPageView']);
  }
}
